<template>
  <div>
    <v-container>
      <SettingsGear :showIcon="true" />
      <div style="height: 15vh">
        <div style="height: 15vh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
          >
            <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: flex-end;
          "
        >
          <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
        </div>
        <div style="height: 66vh">
          <div style="margin-top: 3vh"></div>
          <div class="support-title text-uppercase">support</div>
          <!-- placeholder="Please describe your questions, comments or concerns..." -->
          <div class="support-content">
            <textarea
              class="content-textarea"
              placeholder="Please enter the question you want to ask us, or the problem you encoutner, we will respond to you via in-app message or email"
              @blur="onBlurTextarea"
              @focus="onFocusTextarea"
              maxlength="250"
              v-model="supportContent"
            ></textarea>
            <div class="content-tips">
              OPTIONAL:<br />
              Upload any media related to your inquiry
            </div>
            <div class="content-uplodaer">
              <div
                class="content-add"
                @click="onClickSupportAdd"
                v-if="imageDataList.length != 3"
              >
                <img src="@/assets/ui/upload.png" />
                <!-- <span>Upload</span> -->
              </div>

              <van-uploader
                ref="uplodaerImage"
                v-model="imageDataList"
                multiple
                :max-count="3"
                :max-size="10000 * 1024"
                preview-size="95"
                accept="image/*"
                :before-read="beforeRead"
                :after-read="afterRead"
                @oversize="onOversize"
              />
              <!-- <div class="image-list">
                <div
                  class="image-item"
                  v-for="(data, index) in imageDataList"
                  :key="index"
                >
                  <img :src="data" />
                </div>
              </div> -->
            </div>
            <span class="content-msg"
              >.JPG, .GIF, or .PNG Only<br />
              Max size 3 Mb
            </span>
            <div
              style="
                border-radius: 30px;
                border: 2px solid #ffab00;
                margin-top: 2vh;
              "
            >
              <p
                class="text-center text-white text-uppercase"
                style="
                  font-size: 1.3em;
                  font-weight: bold;
                  margin: 6px 0px !important;
                "
                @click="onClickSubmit"
              >
                Submit
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer ref="footer" />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { Toast, Uploader } from "vant";

Vue.use(Uploader);

export default {
  name: "support",
  data() {
    return {
      imageDataList: [],
      maxSize: 10000,
      defaultPhoneHeight: 0, //屏幕默认高度
      nowPhoneHeight: 0, //屏幕现在的高度
      supportContent: "", //反馈内容
    };
  },
  created() {},
  methods: {
    onBlurTextarea(event) {
      this.$refs.footer.$el.style.display = "block";
    },
    onFocusTextarea(event) {
      this.$refs.footer.$el.style.display = "none";
    },
    onClickSupportAdd() {
      this.$refs["uplodaerImage"].chooseFile();
    },
    // 预览图片显示图片
    preview(files) {},
    // 上传前置处理
    beforeRead(file) {
      if (file.length > 3) {
        // Toast("A maximum of 3 pictures can be uploaded");
        this.$showTips({
          msg: "A maximum of 3 pictures can be uploaded",
          duration: 3000,
          showType: "info",
        });
        return false;
      } else if (file.length >= 2) {
        let resp = true;
        file.forEach((element) => {
          if (
            element.type != "image/png" &&
            element.type != "image/gif" &&
            element.type != "image/jpeg" &&
            element.type != "image/jpg"
          ) {
            // Toast("Please upload pictures in JPG / PNG / GIF format.");
            this.$showTips({
              msg: "Please upload pictures in JPG / PNG / GIF format.",
              duration: 3000,
              showType: "info",
            });
            resp = false;
          }
        });
        if (!resp) return false;
      } else {
        if (
          file.type != "image/png" &&
          file.type != "image/gif" &&
          file.type != "image/jpeg" &&
          file.type != "image/jpg"
        ) {
          // Toast("Please upload pictures in JPG / PNG / GIF format.");
          this.$showTips({
            msg: "Please upload pictures in JPG / PNG / GIF format.",
            duration: 3000,
            showType: "info",
          });
          return false;
        }
      }
      return true;
    },
    // 文件上传完毕后触发
    afterRead(file) {
      console.log(file);
    },
    // 文件大小提示
    onOversize(file) {
      // Toast("Maximum file size is 3MB");
      this.$showTips({
        msg: "Maximum file size is 3MB",
        duration: 3000,
        showType: "info",
      });
    },
    // 提交反馈
    onClickSubmit() {
      if (this.supportContent == "" || this.supportContent == null) {
        this.$showTips({
          // msg: "Please describe your questions, comments or concerns...",
          msg: "Please enter your question in the text box.",
        });
        // Toast.fail("Please describe your questions, comments or concerns...");
        return false;
      }
      let params = new FormData();
      this.imageDataList.forEach((element) => {
        params.append("file", element.file);
      });
      params.append("content", this.supportContent);

      this.showLoading("Loading");
      this.$api.feedback
        .feedbackAdd(params)
        .then((data) => {
          this.hideLoading();
          // Toast.success(data.message);
          this.$showTips({
            msg: data.message,
            duration: 3000,
            showType: "info",
          });
          this.supportContent = "";
          this.imageDataList = [];
        })
        .finally((res) => {});
      //this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["getMainColor", "getAccentColor"]),
  },
  mounted() {
    this.defaultPhoneHeight = window.innerHeight;
    window.onresize = () => {
      this.nowPhoneHeight = window.innerHeight;
    };
  },
  destroyed() {
    window.onresize = null;
  },
  watch: {
    nowPhoneHeight: function () {
      if (this.defaultPhoneHeight != this.nowPhoneHeight) {
        //手机键盘被唤起了。
        this.$refs.footer.$el.style.display = "none";
      } else {
        //手机键盘被关闭了。
        this.$refs.footer.$el.style.display = "block";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-height: 568px) and (max-height: 666px) {
  .support-content {
    .content-textarea {
      height: 20vh !important;
    }
    .content-uplodaer {
      .content-add {
        width: 80px !important;
        height: 80px !important;
      }
      /deep/ .van-uploader__preview-image {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
}

.support-title {
  color: #ffffff;
  font-size: 3vh;
  font-weight: bold;
  text-align: center;
}
.support-content {
  .content-textarea {
    border: 2px solid #ffab00;
    border-radius: 20px;
    width: 100%;
    height: 23vh;
    color: #ffffff;
    padding: 10px 15px;
    outline: none;
    resize: none;
    // margin-top: 2vh;
    text-align: center;
  }
  textarea::-webkit-input-placeholder {
    /*WebKit browsers*/
    color: #ffffff;
  }
  .content-tips {
    color: #ffffff;
    font-size: 1.6vh;
    text-align: center;
    margin-top: 1vh;
  }
  .content-uplodaer {
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-add {
    width: 95px;
    height: 95px;
    border: 2px solid #ffab00;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 80px;
      margin-top: 10px;
    }

    span {
      color: #999999;
    }
  }

  .content-msg {
    display: flex;
    margin-top: 2vh;
    color: #ffffff;
    justify-content: center;
    text-align: center;
    font-size: 1.5vh;
    line-height: 1.5vh;
  }
}
/deep/ .van-uploader__upload {
  display: none !important;
}

/deep/ .van-uploader__preview {
  margin: 0 5px;
}
</style>
